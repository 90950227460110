body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
